import { useEffect } from 'react';
import { useUser } from '@unisporkal/user';
import { useSite } from '@unisporkal/sites';
import getLastSearchScope from '../../utils/getLastSearchScope';

export default (searchBar) => {
  const user = useUser();
  const site = useSite();

  useEffect(() => {
    const lastSearch = getLastSearchScope(site);
    if (!searchBar.options.rememberLastSearchScope || !lastSearch.assettype) {
      return;
    }
    let { assettype, family } = lastSearch;
    if (
      assettype === 'image' &&
      family === 'creative' &&
      user.imageFilmGrid.defaultToImageFilm
    ) {
      assettype = 'image,film';
    }
    if (
      assettype !== 'image' &&
      family === 'editorial' &&
      searchBar.searchParameters.family === 'creative'
    ) {
      family = 'creative,editorial';
    }
    searchBar.updateSearchBarSearchParameters({
      assettype,
      family,
    });
  }, []);
};
