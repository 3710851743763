import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTimeout, useEventListener } from '@unisporkal/utilities';
import { useSearchPathBuilder } from '@unisporkal/search-url-builder';
import { useExperience } from '@unisporkal/experiences';
import { useSearchBar, useRememberScope } from '../../../hooks';

const SearchBar = ({ children, styles }) => {
  const searchBar = useSearchBar();
  const searchPathBuilder = useSearchPathBuilder();
  const container = useRef();
  const ref = useRef();
  const lastScrollPosition = useRef();
  const [beginDelayOnFocusTimout] = useTimeout(250);
  useRememberScope(searchBar);
  const editorialRecommendExp = useExperience('EditorialRecommendations');

  const requestRecommendations = () =>
    editorialRecommendExp.isOfferActive('expA') &&
    searchBar.editorialImageSearch();

  const search = () => {
    const params = {};
    Object.keys(searchBar.searchParameters).forEach((param) => {
      if (searchBar.searchParameters[param]) {
        params[param] = searchBar.searchParameters[param];
      }
    });

    const paramsCopy = { ...params };
    if (requestRecommendations()) {
      paramsCopy.recommendconfig = 'recommendandapply';
    }

    window.location = searchPathBuilder.traditionalSearchPath(paramsCopy);
  };

  const handleOverlayOnScroll = () => {
    if (searchBar.options.hideOnScroll && searchBar.inFocus) {
      searchBar.updateSearchBar({ inFocus: false });
    }
  };

  const isScrollingUp = (position) =>
    lastScrollPosition.current && lastScrollPosition.current > position;

  const handlePeekyScrollOnScroll = () => {
    if (!searchBar.options.peekyScroll) return;
    if (!container.current) return;

    let newMode = null;
    const { height } = container.current.getBoundingClientRect();
    const position = container.current.getBoundingClientRect().top;
    const scrollPosition = document.scrollingElement.scrollTop;

    if (position > 0) {
      newMode = 'none';
    } else if (isScrollingUp(scrollPosition)) {
      newMode = 'docked';
    } else if (position > height) {
      newMode = 'docked-up';
    } else {
      newMode = 'none';
    }
    lastScrollPosition.current = scrollPosition;
    if (newMode !== searchBar.peekyScrollMode) {
      searchBar.updateSearchBarPeekyMode(newMode);
    }
  };

  const onScroll = () => {
    handleOverlayOnScroll();
    handlePeekyScrollOnScroll();
  };

  const [enableScroll, disableScroll] = useEventListener('scroll', onScroll);

  const peekyScrollClassNames = () => {
    const classNames = [];
    if (searchBar.options.peekyScroll) {
      if (searchBar.peekyScrollMode === 'docked') {
        classNames.push(styles.docked);
      } else if (searchBar.peekyScrollMode === 'docked-up') {
        classNames.push(styles.docked);
        classNames.push(styles.up);
      }
    }
    return classNames.join(' ');
  };

  useEffect(() => {
    disableScroll();
    if (searchBar.inFocus || searchBar.options.peekyScroll) {
      enableScroll();
    }
  }, [searchBar.inFocus, searchBar.options.peekyScroll]);

  useEffect(() => {
    // when scroll bar focused into, give a delay for the search bar to be scrolled into
    // view (mobile) before listening again on whether to hide on scroll
    if (searchBar.options.hideOnScroll && searchBar.inFocus) {
      disableScroll();
      beginDelayOnFocusTimout(enableScroll);
    }
  }, [searchBar.inFocus]);

  useEffect(() => {
    if (!searchBar.updated()) {
      return;
    }

    if (searchBar.options.performSearch) {
      search();
    } else {
      const event = new CustomEvent('searchbar.updated', {
        detail: searchBar.searchParameters,
      });
      document.dispatchEvent(event);
    }
    searchBar.setStatusToReady();
  }, [searchBar.status]);

  useEffect(() => {
    const handler = (e) => {
      searchBar.updateSearchBar(e.detail);
    };
    document.addEventListener('search.updated', handler);

    return () => document.removeEventListener('search.updated', handler);
  }, []);

  return (
    <div
      ref={container}
      className={styles.container}
      data-testid="container-search-bar"
    >
      <div
        ref={ref}
        className={`${styles.content} ${peekyScrollClassNames()}`}
      >
        {children}
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.shape().isRequired,
};

export default SearchBar;
