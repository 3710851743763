import React from 'react';
import { lazyProps } from '@unisporkal/react-lazy-component';
import { LocalizationProvider } from '@unisporkal/localization';
import { UserProvider } from '@unisporkal/user';
import PropTypes from 'prop-types';
import SearchBarProvider from './search-bar-provider/SearchBarProvider';
import configPropType from '../proptypes/configPropType';

const Translations = lazyProps((props) =>
  import(`locales/${props.siteName}/${props.locale}`)
);

const Providers = ({ children, config = {}, locale, siteName, user = {} }) => (
  <UserProvider user={user}>
    <SearchBarProvider config={config}>
      <Translations
        siteName={siteName}
        locale={locale}
      >
        {(translations) => (
          <LocalizationProvider
            translations={translations}
            locale={locale}
          >
            {children}
          </LocalizationProvider>
        )}
      </Translations>
    </SearchBarProvider>
  </UserProvider>
);

Providers.propTypes = {
  siteName: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  config: configPropType,
  user: PropTypes.shape(),
};

export default Providers;
