const APP_NAME = 'search-bar';
const COMPONENTS = {
  SIMPLE_SEARCH_BAR: 'SimpleSearchBar',
  SEARCH_BAR: 'SearchBar',
  ISTOCK_COLLAPSIBLE_SEARCH_BAR: 'IstockCollapsibleSearchBar',
};
const AUTOSUGGEST_ENDPOINT =
  'https://as.gettyservices.com:443/GettyImages.Autocomplete.KeywordService.Service/KeywordService1/Suggestedkeywords';

const GETTY_PLACEHOLDERS = {
  searchbyimage: 'search_box.search_bar_similar_images_placeholder',
  morelikethis: 'search_box.search_bar_similar_images_placeholder',
  image: 'search_box.search_all_images',
  'image.creative': 'search_box.search_creative_photos_and_images',
  'image.creative.photography': 'search_box.search_creative_photos_and_images',
  'image.creative.illustration': 'search_box.search_creative_photos_and_images',
  'image.creative.illustration.eps':
    'search_box.search_creative_photos_and_images',
  'image.editorial': 'search_box.search_editorial_photos',
  imagefilm: 'search_box.search_creative_image_and_film',
  film: 'search_box.search_all_videos',
  'film.creative': 'search_box.search_creative_footage_and_video',
  'film.editorial': 'search_box.search_editorial_footage_and_video',
};

const ISTOCK_PLACEHOLDERS = {
  searchbyimage: 'search_box.search_bar_similar_images_placeholder',
  morelikethis: 'search_box.search_bar_similar_images_placeholder',
  image: 'general_search_hint_with_video',
  'image.creative': 'general_search_hint_with_video',
  'image.creative.photography': 'general_search_hint_with_video',
  'image.creative.illustration': 'general_search_hint_with_video',
  'image.creative.illustration.eps': 'general_search_hint_with_video',
  film: 'general_search_hint_with_video',
  'film.creative': 'general_search_hint_with_video',
  imagefilm: 'general_search_hint_with_video',
};

export {
  APP_NAME,
  COMPONENTS,
  AUTOSUGGEST_ENDPOINT,
  GETTY_PLACEHOLDERS,
  ISTOCK_PLACEHOLDERS,
};
