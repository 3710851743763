import emptyState from './emptyState';

const buildInitialState = (config, restOfProps) => ({
  ...emptyState,
  ...config,
  options: {
    ...emptyState.options,
    ...config.options,
  },
  searchParameters: {
    ...emptyState.searchParameters,
    ...config.searchParameters,
  },
  config: {
    ...emptyState.config,
  },
  ...restOfProps.actionDispatchers,
});

export default buildInitialState;
